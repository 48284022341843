import { AccountStats } from "@/services/account.service";
import { routes } from "@/services/account.service";
import { fetcher } from "@/lib/apiFetch";
import useSWR from "swr";
import { fetcherV2 } from "@/services/api";

export function useAccountStats() {
  const { data, error } = useSWR(
    () => [routes.getStats, {}, true],
    fetcherV2<AccountStats>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    accountStats: data,
    isLoading: !error && !data,
    isError: error,
  };
}
