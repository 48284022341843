import { Body, Heading } from "@/styles/typography";
import Page from "@/containers/Page";
import { Col, Div, Row } from "@/styles/layout";
import { useAccountStats } from "@/hooks/useAccountStats";
import { currency } from "@/lib/format";
import { IconType } from "react-icons";
import { FaDonate, FaHandHoldingHeart } from "react-icons/fa";
import { RiTeamFill } from "react-icons/ri";
import { motion } from "framer-motion";
import { usePageInfo } from "@/hooks/usePageInfo";
import { useAccount } from "@/hooks/useAccount";
import { useEffect } from "react";
import Loader from "@/components/Loader";
import { GiTwoCoins } from "react-icons/gi";
import { useNotifications } from "@/hooks/NotificationsProvider";
import { useStripeConnectLink } from "@/hooks/useStripeConnectLink";
import { BsCalendar2Check } from "react-icons/bs";
import Graph from "@/components/Graph";
import { useDonationTimeSerie } from "@/hooks/useDonationTimeSerie";

interface CardProps {
  gridArea: string;
  title: string;
  value: string | number | undefined;
  Icon: IconType;
  loading: boolean;
  iconColor?: string | undefined;
  highlight?: boolean;
}

const Card = ({
  gridArea,
  title,

  value,
  loading,
  highlight = false,
}: CardProps) => (
  <motion.div
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0 }}
    style={{ gridArea }}
  >
    <Col
      alignItems={"stretch"}
      p="24px"
      bg={highlight ? "primary.accent" : "primary.bgBase"}
      borderRadius="4px"
    >
      <Row mb="8px" justifyContent={"flex-start"}>
        <Heading
          fontSize="14px"
          fontWeight="400"
          color={highlight ? "primary.element" : "gray.10"}
        >
          {title.toUpperCase()}
        </Heading>
      </Row>

      <Col alignItems={"stretch"} mt={"-8px"}>
        {loading ? (
          <Row pt="8px">
            <Loader size={24} />
          </Row>
        ) : (
          <Body
            fontSize="32px"
            fontWeight="600"
            color={highlight ? "primary.bgBase" : "primary.textHigh"}
          >
            {value}
          </Body>
        )}
      </Col>
    </Col>
  </motion.div>
);

export default function Home() {
  const { addNotification } = useNotifications();
  const { accountStats, isLoading } = useAccountStats();
  const routeInfo = usePageInfo();
  const { account, isLoading: accountLoading, isError } = useAccount();

  const {
    stripeConnectLink,
    isLoading: isLinkLoading,
    error,
  } = useStripeConnectLink(
    !accountLoading && !!account && !account.stripeConnected
  );

  useEffect(() => {
    if (
      !accountLoading &&
      account &&
      !account.stripeConnected &&
      !isLinkLoading &&
      stripeConnectLink
    ) {
      addNotification({
        id: "stripe_not_connected",
        mode: "critical",
        dismissable: false,
        payload: (
          <div className="flex items-center space-x-4 text-black">
            <div className="text-white">Connect Stripe</div>
            <a
              href={stripeConnectLink.url}
              className="bg-white rounded-sm text-black px-4 py-2 text-sm"
            >
              Connect
            </a>
          </div>
        ),
      });
    }
  }, [
    account,
    accountLoading,
    addNotification,
    isLinkLoading,
    stripeConnectLink,
  ]);

  const { donations } = useDonationTimeSerie({});
  return (
    <Page
      title={`${routeInfo.name} | GoGive`}
      header={routeInfo.name!}
      Icon={routeInfo.icon!}
    >
      <Div
        display="grid"
        gridTemplateAreas={"'raised donors x' 'donations monthly plans'"}
        gridGap="24px"
      >
        <Card
          gridArea="raised"
          title="Raised"
          Icon={FaHandHoldingHeart}
          value={currency((accountStats?.raised ?? 0) / 100)}
          loading={isLoading}
          highlight
        />
        <Card
          gridArea="donors"
          title="Donors"
          Icon={RiTeamFill}
          value={accountStats?.donors}
          loading={isLoading}
        />

        <Card
          gridArea="donations"
          title="Donations"
          Icon={FaDonate}
          value={accountStats?.donations}
          loading={isLoading}
        />
        <Card
          gridArea="plans"
          title="Round-up Plans"
          Icon={GiTwoCoins}
          value={accountStats?.roundupPlans}
          loading={isLoading}
        />
        <Card
          gridArea="monthly"
          title="Recurring Plans"
          Icon={BsCalendar2Check}
          value={accountStats?.recurringPlans}
          loading={isLoading}
        />
      </Div>
      {donations.length > 5 && (
        <Col mt="32px">
          <Graph donations={donations} />
        </Col>
      )}
    </Page>
  );
}
