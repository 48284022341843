import { routes } from "@/services/account.service";
import useSWR from "swr";
import Stripe from "stripe";
import { fetcherV2 } from "@/services/api";

export function useStripeConnectLink(enabled: boolean) {
  const { data, error, isLoading } = useSWR(
    () =>
      enabled
        ? [
            routes.getStripeConnectLink,
            {
              returnUrl: window.location.href,
              refreshUrl: window.location.href,
            },
          ]
        : undefined,
    fetcherV2<Stripe.AccountLink | undefined>
  );

  return {
    isLoading,
    error,
    stripeConnectLink: data,
  };
}
