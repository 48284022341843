import React, { useMemo } from "react";
import { motion } from "framer-motion";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Donations } from "@/types/models/Donations";
import { currency } from "@/lib/format";
import { getCurrentTheme } from "@/styles/theme";
import { Div } from "@/styles/layout";

interface GraphProps {
  donations: Donations[];
}

const Graph: React.FC<GraphProps> = ({ donations }) => {
  const data = useMemo(() => {
    const dateRange = generateDateRange(donations);

    const groupedDonations = donations
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reduce((accumulator, donation) => {
        const date = new Date(donation.createdAt).toLocaleDateString();
        if (!accumulator[date]) {
          accumulator[date] = 0;
        }
        accumulator[date] += donation.grossAmount / 100;
        return accumulator;
      }, {} as Record<string, number>);
    return dateRange.map((date) => ({
      date,
      volume: groupedDonations[date] || 0,
    }));
  }, [donations]);

  return (
    <Div
      style={{
        width: "100%",
        height: 400,
        backgroundColor: getCurrentTheme().colors.primary.bgBase,
        borderRadius: getCurrentTheme().radii[0],
        padding: 24,
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <XAxis dataKey="date" interval="preserveStartEnd" />
          <YAxis tickFormatter={(e) => currency(e, 0)} />
          <CartesianGrid stroke="#eee" />
          <Tooltip formatter={(d, v) => [currency(d as string, 2)]} />
          <Line
            type="linear"
            dataKey="volume"
            stroke={getCurrentTheme().colors.primary.accent}
            dot={false}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Div>
  );
};

export default Graph;

const generateDateRange = (donations: Donations[]) => {
  const sortedDonations = donations.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  const startDate = new Date(sortedDonations[0].createdAt);
  const endDate = new Date(
    sortedDonations[sortedDonations.length - 1].createdAt
  );
  const dateRange = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    dateRange.push(currentDate.toLocaleDateString());
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateRange;
};
